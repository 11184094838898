/** html description */

/* paraph */
.my-description p {
  font-size: 16px;
  color: #5d646d;
  line-height: 28px;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: var(--font-roboto);
}

/* blockquote */

.my-description blockquote {
  /* need blockquote style with left border 2px solid #192362 and gap between border adn text 16px */
  display: block;
  font-size: 20px;
  font-style: italic;
  line-height: 31px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0 0 0 20px;
  border-left: 4px solid #287dfa;
  border-width: 0 0 0 5px;
}

.my-description blockquote p {
  font-family: var(--font-roboto);
  color: #5d646d;
  font-size: 20px;
  font-style: italic;
  line-height: 31px;
}

.my-description pre {
  font-size: 16px;
  line-height: 2;
  color: #686868;
  line-height: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow-x: auto;
  font-family: var(--font-roboto);
}

/* h1 */
.my-description h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 28px;
  font-weight: 600;
  font-family: var(--font-poppins);
  line-height: 1.167;
}

/* h2 */
.my-description h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 27px;
  font-weight: 700;
  line-height: 1.2;
  font-family: var(--font-poppins);
}

/* h3 */
.my-description h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.7138rem;
  font-family: var(--font-poppins);
  font-weight: 400;
  line-height: 1.167;
}

/* h4 */
.my-description h4 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: var(--font-poppins);
  font-weight: 400;
  font-size: 1.417rem;
}

/* h5 */
.my-description h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-family: var(--font-poppins);
  font-weight: 400;
  line-height: 1.334;
  font-weight: 600;
}

/* h6 */
.my-description h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  font-family: var(--font-poppins);
  font-weight: 400;
  line-height: 1.334;
  font-weight: 600;
}

/* link */
.my-description a {
  color: #007bff;
}
.my-description a:hover {
  cursor: pointer;
  color: #0056b3;
}

/* table */
.my-description table {
  width: 100%;
  margin-bottom: 0;
  font-family: var(--font-roboto);
  background-color: #fff;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  border-radius: 5px;
  border-spacing: 29px 0;
  border-collapse: separate;
}
.my-description th {
  text-align: left;
  font-size: 15px;
  padding: 12px;
  border-top: 1px solid rgba(128, 137, 150, 0.1);
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  font-weight: 500;
  color: #0d233e;
  font-family: var(--font-poppins);
}
.my-description td {
  font-size: 15px;
  text-align: left;
  padding: 12px;
  border-top: 1px solid rgba(128, 137, 150, 0.1);
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  font-family: var(--font-roboto);
  color: #5d646d;
}

/* List */
.my-description ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2em;
  font-family: var(--font-roboto);
}

.my-description ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2em;
  list-style-type: decimal;
  font-family: var(--font-roboto);
}
.my-description li {
  list-style-position: outside;
  margin-top: .6rem;
  margin-bottom: .6rem;
  font-family: var(--font-roboto);
  color: #000000de;
}
.my-description li p {
  display: inline;
  color: #000000de;
}
.my-description ul {
  list-style: disc;
}

/* Grid */
.__se__float-right {
  float: right;
  margin: 0 0 1rem 1rem;
}
.__se__float-center {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}

/* Media query */

@media (min-width: 600px) {
  .my-description h1 {
    font-size: 44px;
  }

  .my-description h2 {
    font-size: 27px;
  }

  .my-description h3 {
    font-size: 1.4996rem;
  }

  .my-description h4 {
    font-size: 1.417rem;
  }

  .my-description h5 {
    font-size: 16px;
  }

  .my-description h6 {
    font-size: 14px;
  }
}

@media (min-width: 900px) {
  .my-description h1 {
    font-size: 58px;
  }

  .my-description h2 {
    font-size: 27px;
  }

  .my-description h3 {
    font-size: 1.7138rem;
  }

  .my-description h4 {
    font-size: 1.417rem;
  }

  .my-description h5 {
    font-size: 20px;
  }

  .my-description h6 {
    font-size: 16px;
  }
}

@media (min-width: 0px) {
  .my-description h1 {
    font-weight: 500;
  }
}

@media (min-width: 1200px) {
  .my-description h1 {
    font-size: 66px;
    font-weight: 600;
  }

  .my-description h2 {
    font-size: 32px;
  }

  .my-description h3 {
    font-size: 1.7138rem;
  }

  .my-description h4 {
    font-size: 1.417rem;
  }

  .my-description h5 {
    font-size: 22px;
  }

  .my-description h6 {
    font-size: 18px;
  }
}
