@tailwind base;
@tailwind components;
@tailwind utilities;

/* import css file from path: /public/assets/css/textEditorStyle.css */
@import '../../public/assets/css/textEditorStyle.css';


/* Image slider overrides */
.slick-next {
  width: 73px !important;
  height: 73px !important;
}

.slick-next {
  right: 20px !important;
}

/** Mui Accordion component */
.mui-accordion-expanded-override {
  background-color: #e8f1ff !important; /* webSecondaryContainer color */
}

/** Mui ListItemButton component  */
.mui-list-item-button-selected {
  background-color: #e8f1ff !important; /* webSecondaryContainer color */
}

.recharts-cartesian-grid-vertical line {
  stroke-opacity: 0;
}

/** react-transition-group */

/* fade */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

